import React from 'react';
import {
  BillboardV2,
  Hyperlink,
  Button,
  Body,
  H1,
  Colors
} from '@paddl/storybook';
import {
  Layout,
  LoginCard
} from '../components';
import DiscoverPaddlArtwork from '../public/assets/discover-paddl.svg';
import {
  HomeWrapper,
  LinkWrapper
} from '../styles/pages/index.styles';
import styled from '@emotion/styled';

const Title = styled.h1`
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
`

const Home = () =>
    <Layout
      pageTitle="Stay ready for the future of work."
      pageMetaImageUrl={`${process.env.PUBLIC_URL}/assets/social-share.png`}
      // eslint-disable-next-line max-len
      pageDescription="Paddl delivers rapid upskilling for high performance teams in an all-in-one LXP. Supercharge your learning and development with live workshops and content, guided by experience analytics on Paddl."
    >
      <HomeWrapper>
        <section className="section-wrapper billboard">
          <div className="section-inner-wrapper">
            <BillboardV2
              title={<Title>Stay ready for the <span className="coloured-titled">future of work.</span></Title>}
              // eslint-disable-next-line max-len
              textContent={<Body style={{color: Colors.new_grey}}>Paddl delivers live workshops and future skills content to empower your workday. Upskill together as a team, unlock badges and earn XP to stay ready for the challenges of tomorrow.</Body>}
              btnUrl="https://about.paddl.com"
              btnText="⚡ Learn More"
              artwork={<LoginCard />}
              direction="imageRight"
              color="pink"
            >
              <LinkWrapper>
                <Hyperlink href="/api/auth/login?initialPage=register" target="_self">
                  Sign up for Paddl Personal →
                </Hyperlink>
              </LinkWrapper>
            </BillboardV2>
          </div>
        </section>
        <section className="section-wrapper about">
          <div className="section-inner-wrapper">
            <BillboardV2
              title={<H1 as="h2" style={{color: 'white'}}>Discover how Paddl can help your team <span className="coloured-titled">take on tomorrow.</span></H1>}
              btnUrl="https://about.paddl.com"
              btnText="⚡ Learn More"
              artwork={<DiscoverPaddlArtwork />}
              direction="imageRight"
              color="pink"
              isLightTheme
            >
              <LinkWrapper>
                <Button
                  className="button"
                  href="https://about.paddl.com/enquiry"
                  size="extra-large"
                  color="white"
                  data-button="get-in-touch"
                >
                  💬 Contact Sales
                </Button>
              </LinkWrapper>
            </BillboardV2>
          </div>
        </section>
      </HomeWrapper>
    </Layout>;

export default Home;
