import styled from '@emotion/styled';
import {
  viewportWidthExtraSmall,
  viewportWidthSmall,
  viewportWidthMedium,
  Colors,
  Fonts
} from '@paddl/storybook';

const {
  H3
} = Fonts;

const {
  white,
  plum_paddl
} = Colors;

const contentWidth = '1200px';

// eslint-disable-next-line max-len
const landscapeBased64 = 'PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAyOCAzMCIgZmlsbD0ibm9uZSIgZmlsbC1vcGFjaXR5PSIwLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4wMDA0ODgyODEgMjhMMC4wMDA0ODgyODEgMEwxLjUwMDQ5IDBMMS41MDA0OSAyOEgwLjAwMDQ4ODI4MVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi44NTM1ZS0wOSAyOEwyOCAyOEwyOCAyOS41TC01Ljg5MDg3ZS0wOCAyOS41TDYuODUzNWUtMDkgMjhaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K';
// eslint-disable-next-line max-len
const landscapeBased64WithOpacity = 'PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAyOCAzMCIgZmlsbC1vcGFjaXR5PSIwLjA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMDAwNDg4MjgxIDI4TDAuMDAwNDg4MjgxIDBMMS41MDA0OSAwTDEuNTAwNDkgMjhIMC4wMDA0ODgyODFaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuODUzNWUtMDkgMjhMMjggMjhMMjggMjkuNUwtNS44OTA4N2UtMDggMjkuNUw2Ljg1MzVlLTA5IDI4WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==';

export const HomeWrapper = styled.div`
  .section-wrapper {
    width: 100%;
    padding: 80px 0 90px;
    margin: 0 auto;

    @media (max-width: ${viewportWidthSmall}) {
      padding: 20px 0 90px;
    }

    @media (max-width: ${viewportWidthExtraSmall}) {
      padding: 10px 0 40px;
    }

    &.billboard {
      background: ${`url('data:image/svg+xml;base64,${landscapeBased64}') repeat top center,
        linear-gradient(45deg, ${white} 10%, rgba(255,139,174,0.4) 50%, rgba(226,209,255,0.4) 100%)`};

      .coloured-titled {
        background-image: linear-gradient(92.47deg, #845cf5 29.05%, #f72585 56.28%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &.about {
      background: ${`url('data:image/svg+xml;base64,${landscapeBased64WithOpacity}') repeat top center,
        linear-gradient(45deg, ${plum_paddl} 10%, rgba(83, 32, 163, 0.75) 50%, ${plum_paddl} 100%)`};

      @media (min-width: ${viewportWidthSmall}) {
        max-height: 200px;
        margin-bottom: 140px;
      }

      h3 {
        font-weight: 300;
      }

      .coloured-titled {
        background-image: linear-gradient(180deg, #ffffff 20%, #F65151 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .section-inner-wrapper {
    max-width: ${viewportWidthMedium};
    margin: 0 auto;
    padding: 0 16px;
    box-sizing: content-box;
  }
`;

export const ContentWrapper = styled.div`
  max-width: ${contentWidth};
  background: ${white};
  padding: 16px;
  margin: auto;

  .title {
    ${H3};
    text-align: left;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
